import { createSlice } from '@reduxjs/toolkit';
// import { CATALOG } from '../../data/db';
import { fetchCatalog } from './action';

const initialState = {
  catalog: {}, // ОБНОВА
  // catalog: CATALOG, // Каталог моделей автомобилей
  keyBrand: [], // Список лого и названий
  allRoutName: [], // Список лого и названий
  models: [], //Масив автомобилей

  filteredLogoBrand: [], //Масив для фильтрации логотипов
  filteredModels: [], //Масив для отфильтрованых автомобилей
  modelForId: [], // модель найдена по id
  nameModels: [], //Масив названий моделей авто
  years: [], // Годы выпуска текущей выбранной модели авто в фильтре на главной странице
  bodys: [], // Кузов авто
  markets: [], // поставщик текущей выбранной модели авто
  selectedBrand: '', // Текущая выбранная марка авто
  selectedModel: '', // Текущая выбранная модель авто
  selectedYear: '', // Текущий выбранный год авто
  selectedBody: '', // Кузов авто
  selectedMarket: '', // Текущий выбранный поставщик авто
  loading: false,
  error: null,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,

  reducers: {
    /* ----------------- заполняет масив логотипами и названиями для каталога---------------- */
    populateBrand: (state) => {
      // decoder('populateBrand', state.catalog);

      const brands = Object.keys(state.catalog); //достал ключи из обькта каталога

      state.keyBrand = brands.map((brand) => ({
        name: state.catalog[brand].name,
        logo: state.catalog[brand].logo,
      }));

      state.filteredLogoBrand = state.keyBrand;
      state.allRoutName = state.keyBrand.map(({ name }) => name.replace(/\s/g, '').toLowerCase());
    },

    /* ------------------------- фильтр лого в каталоге ------------------------- */
    filterBrand: (state, { payload }) => {
      if (payload !== 'all') {
        state.filteredLogoBrand = state.keyBrand.filter((brand) =>
          brand.name.toLowerCase().includes(payload.toLowerCase())
        );
      } else {
        state.filteredLogoBrand = state.keyBrand;
      }
    },

    /* --------------- роутинг по нажатию на логотип в каталоге --------------- */
    routeToModelsByBrand: (state, { payload }) => {
      // при совпадении марки отдаём список моделей этой марки
      //в payload получаю название бренда из каталога
      // const notTrim = payload.replace(/\s/g, '').toLowerCase(); //убрал пробелы и понизил регистр
      // const capitalize = notTrim.charAt(0).toUpperCase() + notTrim.slice(1); //поднял регистр у первой буквы

      const capitalize = formatterBrandName(payload);
      console.log('routeToModelsByBrand', capitalize);
      if (payload && state.catalog) {
        const brandData = state.catalog[capitalize];

        if (brandData) {
          // Получаем все модели года поставщика в отдельные массивы
          const allNameModels = brandData.models.map((m) => m.model);
          const allYears = brandData.models.flatMap((m) => m.year);
          const allBody = brandData.models.map((m) => m.body);
          const allMarket = brandData.models.map((m) => m.market);

          // Уникализируем модели года поставщика
          const uniqueNameModels = Array.from(new Set(allNameModels));
          const uniqueYears = Array.from(new Set(allYears)).sort((a, b) => a - b);
          const uniqueBody = Array.from(new Set(allBody));
          const uniqueMarket = Array.from(new Set(allMarket));

          return {
            ...state,

            models: brandData.models, //записываем авто в масив для дальнейшей обработки
            nameModels: uniqueNameModels, //масив уникальных названий моделей
            years: uniqueYears, //масив уникальных годов
            bodys: uniqueBody, //масив уникальных кузовов
            markets: uniqueMarket, //масив уникальных поставщиков
          }; // Возвращаем новый объект состояния с моделями выбран марки, чтобы убедиться в мгновенном обновлении
        } else {
          console.error(`Brand ${payload} not found in catalog`);
          return state; // Возвращаем текущее состояние, если бренд не найден
        }
      } else {
        return {
          ...state,
          models: [],
          nameModels: [],
          years: [],
          bodys: [],
          markets: [],
        }; // Возвращаем новый объект состояния, чтобы убедиться в мгновенном обновлении
      }
    },

    /* -------------- фильтр на главной ------------- */
    filterMainPage: (state, { payload }) => {
      const { selectedBrand, selectedModel, selectedYear } = payload;

      state.selectedBrand = selectedBrand; // Обновление выбранной марки
      state.selectedModel = selectedModel || ''; // Обновление выбранной модели
      state.selectedYear = selectedYear || ''; // Обновление выбранного года
      // Очистка списков моделей и годов при отсутствии выбора марки
      if (!selectedBrand) {
        state.models = [];
        state.filteredModels = [];
        state.nameModels = [];
        state.years = [];
        return;
      }

      // const notTrim = selectedBrand.replace(/\s/g, '').toLowerCase(); //убрал пробелы и понизил регистр
      // const capitalize = notTrim.charAt(0).toUpperCase() + notTrim.slice(1); //поднял регистр у первой буквы
      const capitalize = formatterBrandName(selectedBrand);

      const brandData = state.catalog[capitalize]; //определил бренд модели

      if (brandData) {
        const allNameModels = brandData.models.map((m) => m.model);
        const uniqueNameModels = Array.from(new Set(allNameModels));

        state.nameModels = brandData ? uniqueNameModels : [];
      } else {
        state.nameModels = [];
      }

      if (selectedBrand && selectedModel) {
        const modelData = brandData.models.find((m) => m.model === selectedModel);

        const allYears = brandData.models
          .filter((m) => m.model === state.selectedModel)
          .map((m) => m.year)
          .flat();

        const uniqueYears = Array.from(new Set(allYears)).sort((a, b) => a - b);

        state.years = modelData ? uniqueYears : [];
      } else {
        state.years = [];
      }

      // Фильтрация автомобилей на основе выбранной модели и года
      if (selectedBrand && selectedModel && selectedYear) {
        state.filteredModels = brandData.models.filter(
          (m) => m.model === selectedModel && m.year.includes(selectedYear)
        );
      } else if (selectedBrand && selectedModel) {
        state.filteredModels = brandData.models.filter((m) => m.model === selectedModel);
      } else {
        state.filteredModels = [];
      }
    },

    /* ---------------------------- фильтр в моделях ---------------------------- */
    filterCarModels: (state, { payload }) => {
      const { selectedBrand, selectedModel, selectedYear, selectedBody, selectedMarket } = payload;

      state.selectedBrand = selectedBrand || state.selectedBrand; // Обновление выбранной марки
      state.selectedModel = selectedModel || state.selectedModel; // Обновление выбранной модели
      state.selectedYear = selectedYear !== undefined ? selectedYear : state.selectedYear; // Обновление выбранного года
      state.selectedBody = selectedBody !== undefined ? selectedBody : state.selectedBody; // Обновление выбранного кузова
      state.selectedMarket = selectedMarket || state.selectedMarket; // Обновление выбранного рынка

      const capitalize = formatterBrandName(state.selectedBrand);

      const brandData = state.catalog[capitalize]; // Определил бренд модели

      if (brandData) {
        const allNameModels = brandData?.models.map((m) => m.model);
        const uniqueNameModels = Array.from(new Set(allNameModels));
        state.nameModels = uniqueNameModels;

        // отбираю по марке модели и записываю года

        const allYears = brandData.models
          .filter((m) => m.model === state.selectedModel)
          .map((m) => m.year)
          .flat();
        const uniqueSortYears = Array.from(new Set(allYears)).sort((a, b) => a - b);
        state.years = uniqueSortYears;

        // Фильтрация моделей по выбранной модели и году
        let filteredModels = brandData.models.filter((m) => {
          return (
            (state.selectedModel ? m.model === state.selectedModel : true) &&
            (state.selectedYear && state.selectedYear !== 'Рік' ? m.year.includes(state.selectedYear) : true)
          );
        });

        // Определение уникальных кузовов для отфильтрованных моделей
        const allBodys = filteredModels.map((m) => m.body);
        const uniqueBodys = Array.from(new Set(allBodys));
        state.bodys = uniqueBodys;

        // Определение уникальных рынков для отфильтрованных моделей
        const allMarkets = filteredModels.map((m) => m.market);
        const uniqueMarkets = Array.from(new Set(allMarkets));
        state.markets = uniqueMarkets;

        // Дополнительная фильтрация по кузову
        if (state.selectedBody && state.selectedBody !== 'Тип кузова') {
          filteredModels = filteredModels.filter((m) => m.body === state.selectedBody);
        }

        // Дополнительная фильтрация по рынку
        if (state.selectedMarket && state.selectedMarket !== 'Ринок збуту') {
          filteredModels = filteredModels.filter((m) => m.market === state.selectedMarket);
        }

        state.filteredModels = filteredModels;
      } else {
        state.nameModels = [];
        state.years = [];
        state.bodys = [];
        state.markets = [];
        state.filteredModels = [];
      }
    },

    clearFilters: (state) => {
      state.selectedModel = '';
      state.selectedYear = '';
      state.selectedBody = '';
      state.selectedMarket = '';
      state.filteredModels = state.models;
      state.years = [];
    },

    findModelForId: (state, { payload }) => {
      const match = payload.match(/^([a-zA-Z]+)\d*\?id=(\d+)/);

      if (match) {
        const brand = match[1];
        const id = match[2];

        const capitalize = formatterBrandName(brand);

        const brandData = state.catalog[capitalize]; // Определил бренд модели
        let currentModel;
        if (brandData) {
          currentModel = brandData.models.find((m) => m.id === id);
          state.modelForId = currentModel;
        }

        // if (state.models.length === 0) {
        //   currentModel = brandData?.models.find((m) => m.id === id);

        //   state.modelForId = currentModel;
        // } else {
        // currentModel = state.models.find((m) => m.id === id);
        // state.modelForId = currentModel;
        // }
      }
    },

    // clearModelId: (state) => {
    //   state.modelForId = [];
    // },
  },

  extraReducers: (builder) => {
    // функция заполнения каталога из базы данных
    builder
      .addCase(fetchCatalog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCatalog.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.catalog = payload;

        const brands = Object.keys(state.catalog); //достал ключи из обькта каталога

        state.keyBrand = brands.map((brand) => ({
          name: state.catalog[brand].name,
          logo: state.catalog[brand].logo,
        }));

        state.filteredLogoBrand = state.keyBrand;
      })
      .addCase(fetchCatalog.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const {
  filterBrand,
  populateBrand,
  routeToModelsByBrand,
  filterMainPage,
  clearFilters,
  filterCarModels,
  findModelForId,
  clearModelId,
} = filterSlice.actions;

export const filterReducer = filterSlice.reducer;

/* ---------------------------- Форматтер названия бренда --------------------------- */
function formatterBrandName(value) {
  const notTrim = value.replace(/\s/g, '').toLowerCase(); // Убрал пробелы и понизил регистр
  const capitalize = notTrim.charAt(0).toUpperCase() + notTrim.slice(1); //поднял регистр у первой буквы
  return capitalize;
}
/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
