// цены на чехлы
const price0 = 7199; // Eco=>Sport=>Lazer+Antara(P)(R)=>Vip Elite 2020
const price1 = 7399; // Eco Prime
const price2 = 6199; // Eco Classic 2020
const price3 = 6999; // Antara=>Vip Elite=>Eco Prestige+Antara=>Comfort
const price4 = 6899; // Eco Prestige=>Grand 2020=>Lazer
const price5 = 5699; // Eco Classic
const price6 = 5799; // Classic Premium 2020=>Classic+Antara 2020

export const CTALOG_COVER = [
  {
    id: '1',
    img: '/images/accessories/covers/ecoSport/10.webp',
    imgList: [
      '/images/accessories/covers/ecoSport/10.webp',
      '/images/accessories/covers/ecoSport/es001.webp',
      '/images/accessories/covers/ecoSport/es002.webp',
      '/images/accessories/covers/ecoSport/7.webp',
      '/images/accessories/covers/ecoSport/es003.webp',
      '/images/accessories/covers/ecoSport/es004.webp',
      '/images/accessories/covers/ecoSport/es005.webp',
      '/images/accessories/covers/ecoSport/es006.webp',
      '/images/accessories/covers/ecoSport/es007.webp',
      '/images/accessories/covers/ecoSport/es008.webp',
      '/images/accessories/covers/ecoSport/es009.webp',
      '/images/accessories/covers/ecoSport/es010.webp',
      '/images/accessories/covers/ecoSport/es011.webp',
      '/images/accessories/covers/ecoSport/es012.webp',
      '/images/accessories/covers/ecoSport/es013.webp',
      '/images/accessories/covers/ecoSport/es014.webp',
    ],

    strongText: 'Екошкіра ',
    title: 'Eco Sport Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Sport. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто.',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звіряють її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Sport мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний' },
          { label: 'Сірий', value: 'Сірий' },
        ],
        // вставка
        insertСolor: [
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Білий', value: 'Білий', key: 'white' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Помаранчовий', value: 'Помаранчовий', key: 'orang' },
        ],
        // нитки
        threadСolor: [
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Білий', value: 'Білий', key: 'white' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Помаранчовий', value: 'Помаранчовий', key: 'orang' },
        ],
      },
    ],
    center: false,
    insert: true,
    material: 'eco',
    price: price0,
    page: 'cover',
  },

  {
    id: '2',
    img: '/images/accessories/covers/ecoPrime/epr001.webp',
    imgList: [
      '/images/accessories/covers/ecoPrime/epr001.webp',
      '/images/accessories/covers/ecoPrime/epr002.webp',
      '/images/accessories/covers/ecoPrime/epr003.webp',
      '/images/accessories/covers/ecoPrime/epr004.webp',
      '/images/accessories/covers/ecoPrime/epr005.webp',
      '/images/accessories/covers/ecoPrime/epr006.webp',
      '/images/accessories/covers/ecoPrime/epr007.webp',
      '/images/accessories/covers/ecoPrime/epr008.webp',
      '/images/accessories/covers/ecoPrime/epr009.webp',
      '/images/accessories/covers/ecoPrime/epr010.webp',
      '/images/accessories/covers/ecoPrime/epr011.webp',
      '/images/accessories/covers/ecoPrime/epr012.webp',
    ],

    strongText: 'Комбіновані ',
    title: 'Eco Prime Екошкіра + Антара Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Prime. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто..',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Prime мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'ecoA',
    price: price1,
    page: 'cover',
  },

  {
    id: '3',
    img: '/images/accessories/covers/Antara/a01.jpg',
    imgList: [
      '/images/accessories/covers/Antara/a01.jpg',
      '/images/accessories/covers/Antara/a001.jpg',
      '/images/accessories/covers/Antara/a002.jpg',
      '/images/accessories/covers/Antara/a003.jpg',
      '/images/accessories/covers/Antara/a004.jpg',
      '/images/accessories/covers/Antara/a005.jpg',
      '/images/accessories/covers/Antara/a006.jpg',
      '/images/accessories/covers/Antara/a007.jpg',
      '/images/accessories/covers/Antara/a008.jpg',
      '/images/accessories/covers/Antara/a009.jpg',
      '/images/accessories/covers/Antara/a010.jpg',
      '/images/accessories/covers/Antara/a011.jpg',
      '/images/accessories/covers/Antara/a012.jpg',
      '/images/accessories/covers/Antara/a013.jpg',
      '/images/accessories/covers/Antara/a014.jpg',
      '/images/accessories/covers/Antara/a015.jpg',
      '/images/accessories/covers/Antara/a016.jpg',
      '/images/accessories/covers/Antara/a017.jpg',
      '/images/accessories/covers/Antara/a018.jpg',
      '/images/accessories/covers/Antara/a019.jpg',
      '/images/accessories/covers/Antara/a020.jpg',
      '/images/accessories/covers/Antara/a021.jpg',
      '/images/accessories/covers/Antara/a022.jpg',
      '/images/accessories/covers/Antara/a023.jpg',
      '/images/accessories/covers/Antara/a024.jpg',
      '/images/accessories/covers/Antara/a025.jpg',
      '/images/accessories/covers/Antara/a026.jpg',
      '/images/accessories/covers/Antara/a027.jpg',
      '/images/accessories/covers/Antara/a028.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Antara Екошкіра + Антара Повний комплект',
    text1:
      'Авточохли EVACAR серії Antara. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто..',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Antara мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'ecoA',
    price: price3,
    page: 'cover',
  },

  {
    id: '4',
    img: '/images/accessories/covers/EcoLazerAntara2020Pilot/ela01.jpg',
    imgList: [
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela01.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela001.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela002.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela003.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela004.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela005.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela006.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela007.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela008.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela009.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela010.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela011.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela012.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela013.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela014.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela015.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Pilot/ela016.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Eco Lazer+Antara 2020 (P) Екошкіра + Антара Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Lazer+Antara 2020 (P). Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто..',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Lazer+Antara 2020 (P) мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'ecoA',
    price: price0,
    page: 'cover',
  },

  {
    id: '5',
    img: '/images/accessories/covers/EcoLazerAntara2020Romb/elar01.jpg',
    imgList: [
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar01.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar001.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar002.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar003.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar004.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar005.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar006.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar007.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar008.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar009.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar010.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar011.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar012.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar013.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar014.jpg',
      '/images/accessories/covers/EcoLazerAntara2020Romb/elar015.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Eco Lazer+Antara 2020 (R) Екошкіра + Антара Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Lazer+Antara 2020 (R). Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто..',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Lazer+Antara 2020 (R) мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'ecoA',
    price: price0,
    page: 'cover',
  },

  {
    id: '6',
    img: '/images/accessories/covers/EcoLazer/el01.jpg',
    imgList: [
      '/images/accessories/covers/EcoLazer/el01.jpg',
      '/images/accessories/covers/EcoLazer/el001.jpg',
      '/images/accessories/covers/EcoLazer/el002.jpg',
      '/images/accessories/covers/EcoLazer/el003.jpg',
      '/images/accessories/covers/EcoLazer/el004.jpg',
      '/images/accessories/covers/EcoLazer/el005.jpg',
      '/images/accessories/covers/EcoLazer/el006.jpg',
      '/images/accessories/covers/EcoLazer/el007.jpg',
      '/images/accessories/covers/EcoLazer/el008.jpg',
      '/images/accessories/covers/EcoLazer/el009.jpg',
      '/images/accessories/covers/EcoLazer/el010.jpg',
      '/images/accessories/covers/EcoLazer/el011.jpg',
      '/images/accessories/covers/EcoLazer/el012.jpg',
      '/images/accessories/covers/EcoLazer/el013.jpg',
      '/images/accessories/covers/EcoLazer/el014.jpg',
      '/images/accessories/covers/EcoLazer/el015.jpg',
    ],

    strongText: 'Екошкірa ',
    title: 'Eco Lazer Екошкіра + Перфорація Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Lazer. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто..',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Lazer мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'ecoP',
    price: price4,
    page: 'cover',
  },

  {
    id: '7',
    img: '/images/accessories/covers/VipElite/ve01.jpg',
    imgList: [
      '/images/accessories/covers/VipElite/ve01.jpg',
      '/images/accessories/covers/VipElite/ve001.jpg',
      '/images/accessories/covers/VipElite/ve002.jpg',
      '/images/accessories/covers/VipElite/ve003.jpg',
      '/images/accessories/covers/VipElite/ve004.jpg',
      '/images/accessories/covers/VipElite/ve005.jpg',
      '/images/accessories/covers/VipElite/ve006.jpg',
      '/images/accessories/covers/VipElite/ve007.jpg',
      '/images/accessories/covers/VipElite/ve008.jpg',
      '/images/accessories/covers/VipElite/ve009.jpg',
      '/images/accessories/covers/VipElite/ve010.jpg',
      '/images/accessories/covers/VipElite/ve011.jpg',
      '/images/accessories/covers/VipElite/ve012.jpg',
      '/images/accessories/covers/VipElite/ve013.jpg',
      '/images/accessories/covers/VipElite/ve014.jpg',
      '/images/accessories/covers/VipElite/ve015.jpg',
      '/images/accessories/covers/VipElite/ve016.jpg',
      '/images/accessories/covers/VipElite/ve017.jpg',
      '/images/accessories/covers/VipElite/ve018.jpg',
      '/images/accessories/covers/VipElite/ve019.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Vip Elite Екошкіра + Автотканина Повний комплект',
    text1:
      'Авточохли EVACAR серії Vip Elite. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто..',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Vip Elite мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Помаранчовий', value: 'Помаранчовий', key: 'orang' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Помаранчовий', value: 'Помаранчовий', key: 'orang' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: true,
    material: 'ecoAt',
    price: price3,
    page: 'cover',
  },

  {
    id: '8',
    img: '/images/accessories/covers/VipElite2020/ven01.jpg',
    imgList: [
      '/images/accessories/covers/VipElite2020/ven01.jpg',
      '/images/accessories/covers/VipElite2020/ven001.jpg',
      '/images/accessories/covers/VipElite2020/ven002.jpg',
      '/images/accessories/covers/VipElite2020/ven003.jpg',
      '/images/accessories/covers/VipElite2020/ven004.jpg',
      '/images/accessories/covers/VipElite2020/ven005.jpg',
      '/images/accessories/covers/VipElite2020/ven006.jpg',
      '/images/accessories/covers/VipElite2020/ven007.jpg',
      '/images/accessories/covers/VipElite2020/ven008.jpg',
      '/images/accessories/covers/VipElite2020/ven009.jpg',
      '/images/accessories/covers/VipElite2020/ven010.jpg',
      '/images/accessories/covers/VipElite2020/ven011.jpg',
      '/images/accessories/covers/VipElite2020/ven012.jpg',
      '/images/accessories/covers/VipElite2020/ven013.jpg',
      '/images/accessories/covers/VipElite2020/ven014.jpg',
      '/images/accessories/covers/VipElite2020/ven015.jpg',
      '/images/accessories/covers/VipElite2020/ven016.jpg',
      '/images/accessories/covers/VipElite2020/ven017.jpg',
      '/images/accessories/covers/VipElite2020/ven018.jpg',
      '/images/accessories/covers/VipElite2020/ven019.jpg',
      '/images/accessories/covers/VipElite2020/ven020.jpg',
      '/images/accessories/covers/VipElite2020/ven021.jpg',
      '/images/accessories/covers/VipElite2020/ven022.jpg',
      '/images/accessories/covers/VipElite2020/ven023.jpg',
      '/images/accessories/covers/VipElite2020/ven024.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Vip Elite 2020 Екошкіра + Автотканина Повний комплект',
    text1:
      'Авточохли EVACAR серії Vip Elite 2020. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто..',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Vip Elite 2020 мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Помаранчовий', value: 'Помаранчовий', key: 'orang' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Помаранчовий', value: 'Помаранчовий', key: 'orang' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: true,
    material: 'ecoAt',
    price: price0,
    page: 'cover',
  },

  {
    id: '9',
    img: '/images/accessories/covers/EcoPrestigeAntara/epa01.jpg',
    imgList: [
      '/images/accessories/covers/EcoPrestigeAntara/epa01.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa001.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa002.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa003.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa004.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa005.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa006.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa007.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa008.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa009.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa010.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa011.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa012.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa013.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa014.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa015.jpg',
      '/images/accessories/covers/EcoPrestigeAntara/epa016.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Eco Prestige+Antara Екошкіра + Антара Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Prestige+Antara. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто.',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Prestige+Antara мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // вставка
        insertСolor: [],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'ecoA',
    price: price3,
    page: 'cover',
  },

  {
    id: '10',
    img: '/images/accessories/covers/EcoPrestige/ep01.jpg',
    imgList: [
      '/images/accessories/covers/EcoPrestige/ep01.jpg',
      '/images/accessories/covers/EcoPrestige/ep001.jpg',
      '/images/accessories/covers/EcoPrestige/ep002.jpg',
      '/images/accessories/covers/EcoPrestige/ep003.jpg',
      '/images/accessories/covers/EcoPrestige/ep004.jpg',
      '/images/accessories/covers/EcoPrestige/ep005.jpg',
      '/images/accessories/covers/EcoPrestige/ep006.jpg',
      '/images/accessories/covers/EcoPrestige/ep007.jpg',
      '/images/accessories/covers/EcoPrestige/ep008.jpg',
      '/images/accessories/covers/EcoPrestige/ep009.jpg',
      '/images/accessories/covers/EcoPrestige/ep010.jpg',
      '/images/accessories/covers/EcoPrestige/ep011.jpg',
      '/images/accessories/covers/EcoPrestige/ep012.jpg',
      '/images/accessories/covers/EcoPrestige/ep013.jpg',
      '/images/accessories/covers/EcoPrestige/ep014.jpg',
    ],

    strongText: 'Екошкіра ',
    title: 'Eco Prestige Екошкіра Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Prestige. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто.',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Prestige мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Чорний', value: 'Чорний', key: 'black' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // вставка
        insertСolor: [],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'eco',
    price: price4,
    page: 'cover',
  },

  {
    id: '11',
    img: '/images/accessories/covers/EcoGrand2020/eg01.jpg',
    imgList: [
      '/images/accessories/covers/EcoGrand2020/eg01.jpg',
      '/images/accessories/covers/EcoGrand2020/eg001.jpg',
      '/images/accessories/covers/EcoGrand2020/eg002.jpg',
      '/images/accessories/covers/EcoGrand2020/eg003.jpg',
      '/images/accessories/covers/EcoGrand2020/eg004.jpg',
      '/images/accessories/covers/EcoGrand2020/eg005.jpg',
      '/images/accessories/covers/EcoGrand2020/eg006.jpg',
      '/images/accessories/covers/EcoGrand2020/eg007.jpg',
      '/images/accessories/covers/EcoGrand2020/eg008.jpg',
      '/images/accessories/covers/EcoGrand2020/eg009.jpg',
      '/images/accessories/covers/EcoGrand2020/eg010.jpg',
      '/images/accessories/covers/EcoGrand2020/eg011.jpg',
      '/images/accessories/covers/EcoGrand2020/eg012.jpg',
      '/images/accessories/covers/EcoGrand2020/eg013.jpg',
      '/images/accessories/covers/EcoGrand2020/eg014.jpg',
      '/images/accessories/covers/EcoGrand2020/eg015.jpg',
    ],

    strongText: 'Екошкіра ',
    title: 'Eco Grand 2020 Екошкіра Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Grand 2020. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто.',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Grand 2020 мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // вставка
        insertСolor: [],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'eco',
    price: price4,
    page: 'cover',
  },

  {
    id: '12',
    img: '/images/accessories/covers/EcoClassic/ec01.jpg',
    imgList: [
      '/images/accessories/covers/EcoClassic/ec01.jpg',
      '/images/accessories/covers/EcoClassic/ec001.jpg',
      '/images/accessories/covers/EcoClassic/ec002.jpg',
      '/images/accessories/covers/EcoClassic/ec003.jpg',
      '/images/accessories/covers/EcoClassic/ec004.jpg',
      '/images/accessories/covers/EcoClassic/ec005.jpg',
      '/images/accessories/covers/EcoClassic/ec006.jpg',
      '/images/accessories/covers/EcoClassic/ec007.jpg',
      '/images/accessories/covers/EcoClassic/ec008.jpg',
      '/images/accessories/covers/EcoClassic/ec009.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Eco Classic Екошкіра + Автотканина Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Classic. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто.',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Classic мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // вставка
        insertСolor: [],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'ecoAt',
    price: price5,
    page: 'cover',
  },

  {
    id: '13',
    img: '/images/accessories/covers/EcoClassic2020/ecl01.jpg',
    imgList: [
      '/images/accessories/covers/EcoClassic2020/ecl01.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl001.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl002.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl003.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl004.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl005.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl006.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl007.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl008.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl009.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl010.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl011.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl012.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl013.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl014.jpg',
      '/images/accessories/covers/EcoClassic2020/ecl015.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Eco Classic 2020 Екошкіра + Автотканина Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Classic 2020. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто.',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Classic 2020 мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // нитки
        threadСolor: [{ label: 'Чорний', value: 'Чорний', key: 'black' }],
      },
    ],
    center: true,
    insert: true,
    material: 'ecoAt',
    price: price2,
    page: 'cover',
  },

  {
    id: '14',
    img: '/images/accessories/covers/EcoComfort/ec01.jpg',
    imgList: [
      '/images/accessories/covers/EcoComfort/ec01.jpg',
      '/images/accessories/covers/EcoComfort/ec001.jpg',
      '/images/accessories/covers/EcoComfort/ec002.jpg',
      '/images/accessories/covers/EcoComfort/ec003.jpg',
      '/images/accessories/covers/EcoComfort/ec004.jpg',
      '/images/accessories/covers/EcoComfort/ec005.jpg',
      '/images/accessories/covers/EcoComfort/ec006.jpg',
      '/images/accessories/covers/EcoComfort/ec007.jpg',
      '/images/accessories/covers/EcoComfort/ec008.jpg',
      '/images/accessories/covers/EcoComfort/ec009.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Eco Comfort Екошкіра + Автотканина Повний комплект',
    text1:
      'Авточохли EVACAR серії Eco Comfort. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто.',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Eco Comfort мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
        ],
      },
    ],
    center: true,
    insert: false,
    material: 'ecoAt',
    price: price3,
    page: 'cover',
  },

  {
    id: '15',
    img: '/images/accessories/covers/ClassicPremium2020/cpn01.jpg',
    imgList: [
      '/images/accessories/covers/ClassicPremium2020/cpn01.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn001.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn002.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn003.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn004.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn005.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn006.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn007.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn008.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn009.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn010.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn011.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn012.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn013.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn014.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn015.jpg',
      '/images/accessories/covers/ClassicPremium2020/cpn016.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Classic Premium 2020 Екошкіра + Автотканина Повний комплект',
    text1:
      'Авточохли EVACAR серії Classic Premium 2020. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто.',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Classic Premium 2020 мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Коричневий', value: 'Коричневий', key: 'brown' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: true,
    material: 'ecoAt',
    price: price6,
    page: 'cover',
  },

  {
    id: '16',
    img: '/images/accessories/covers/ClassicAntara2020/ca01.jpg',
    imgList: [
      '/images/accessories/covers/ClassicAntara2020/ca01.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca001.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca002.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca003.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca004.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca005.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca006.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca007.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca008.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca009.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca010.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca011.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca012.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca013.jpg',
      '/images/accessories/covers/ClassicAntara2020/ca014.jpg',
    ],

    strongText: 'Комбіновані ',
    title: 'Classic+Antara 2020 Автотканина + Антара Повний комплект',
    text1:
      'Авточохли EVACAR серії Classic+Antara 2020. Виготовлені з екошкіри високого класу. Поєднання спортивного дизайну та бездоганної посадки на сидіннях вашого авто.',
    text2:
      'Чохли шиємо по індивідуальним лекалам автомобіля враховуючи всі технологічні особливості. Для зниження ризику похибки у сумісності авточохлів з вашою машиною, перед замовленням наші менеджери звірять її комплектацію.',
    text3:
      'Авточохли EVACAR серії Classic+Antara 2020 мають прорізи для установки ременів безпеки. На спинках передніх сидінь, в залежності від моделі авто, розташовані замки з доступом до кишені. У чохлах подушка безпеки з легкістью спрацює при необхідності. Це досягається завдяки використанню ослабленого шву у відповідних місцях AirBag. Чохли сумісні з активними підголівниками, підігрівом, не заважають роботі електронних модулів. Витримують великі навантаження завдяки використанню міцних швів укріплених кантом.',

    colors: [
      {
        // боковины
        sideСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // центр
        centerСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
        ],
        // вставка
        insertСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
        // нитки
        threadСolor: [
          { label: 'Чорний', value: 'Чорний', key: 'black' },
          { label: 'Червоний', value: 'Червоний', key: 'red' },
          { label: 'Сірий', value: 'Сірий', key: 'grey' },
          { label: 'Синій', value: 'Синій', key: 'blue' },
          { label: 'Бежевий', value: 'Бежевий', key: 'beige' },
        ],
      },
    ],
    center: true,
    insert: true,
    material: 'AtA',
    price: price6,
    page: 'cover',
  },
];
