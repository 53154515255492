import { createSlice } from '@reduxjs/toolkit';
import { CTALOG_COVER } from '../../data/dbCover';
const initialState = {
  imgCover: '',
  title: '',
  typeM: '', //тип материала ева\эко
  catalog: CTALOG_COVER,

  sideСolor: [],
  centerColors: [], // для центра
  insertColors: [], // для вставки
  threadColors: [], // для нитки

  selectedSideСolor: '', //цвет боковин
  selectedCenterColor: '', //цвет цента
  selectedInsertColor: '', //цвет вставки
  selectedThreadColor: '', //цвет нитки

  keySideColor: '',
  keyInsertColor: '',

  price: 0,
};

const coverSlice = createSlice({
  name: 'cover',
  initialState,
  reducers: {
    popelateCoverInitialState: (state, { payload }) => {
      const { price, material, title, colors, img, insert, center } = payload;
      state.imgCover = img;
      state.title = title;
      state.typeM = material;
      state.price = price;
      // state.keyInsertColor = 'black';

      // const currentInsertColor = colors[0].insertСolor.find(({ key }) => key === state.keyInsertColor);
      // state.keySideColor = colors[0].key;

      if (colors.length > 0) {
        state.sideСolor = colors[0].sideСolor || [];
        state.centerColors = (center && colors[0].centerСolor) || [];
        state.insertColors = (insert && colors[0].insertСolor) || [];
        state.threadColors = colors[0].threadСolor || [];
        // state.threadColors = [currentInsertColor];
      }

      state.selectedSideСolor = state.sideСolor[0].value;
      state.selectedCenterColor = center && state.centerColors[0].value;
      state.selectedInsertColor = insert && state.insertColors[0].value;
      state.selectedThreadColor = state.threadColors[0].value;
      // state.selectedThreadColor = currentInsertColor.value;

      // state.imgCover = state.insertColors[0].img;
    },

    // боковины
    setSelectedSideСolor: (state, { payload }) => {
      const { key } = payload;
      state.keySideColor = key;
      // шысфеь обьект по ключу
      // из обьекта достать масивы цветов

      const selectedColor = state.sideСolor.find((color) => color.key === key);
      // const findCenterKey = state.selectedColor.find((color) => color.centerColors === 'centerColors');
      // decoder('selectedColor', selectedColor.insertСolor);
      // const currentKeys = Object.keys(selectedColor);
      // console.log('findCenterKey', findCenterKey);

      if (selectedColor) {
        state.selectedSideСolor = selectedColor.value;
        // state.selectedCenterColor = selectedColor.value;
        // state.selectedInsertColor = selectedColor.value;
        // state.selectedThreadColor = selectedColor.value;

        // state.centerColors = selectedColor.centerСolor;
        // state.insertColors = selectedColor.insertСolor;
        // state.threadColors = selectedColor.threadColor;
      }

      /* --------------------------- ЛОГИКА СМЕНЫ ЦВЕТА --------------------------- */
      // const keyColor = selectedColor.insertСolor.find((color) => color.key === state.keyInsertColor);
      // if (selectedColor) {
      //   state.selectedSideСolor = selectedColor.value;
      //   state.selectedCenterColor = selectedColor.value;
      //   state.centerColors = selectedColor.centerСolor;
      //   state.insertColors = selectedColor.insertСolor;
      // }

      // const oppositeColorKey = key === 'grey' && state.keyInsertColor === 'grey' ? 'black' : 'grey';

      // const selectedInsertColor = state.insertColors.find((color) => color.key === oppositeColorKey);
      // if (selectedInsertColor) {
      //   state.selectedInsertColor = selectedInsertColor.value;
      //   state.selectedThreadColor = selectedInsertColor.value;
      //   state.threadColors = [selectedInsertColor];
      // }

      // if (keyColor) {
      //   state.imgCover = keyColor.img;
      // }
    },

    // центр
    setSelectedCenterColor: (state, { payload }) => {
      const { key } = payload;
      // state.selectedCenterColor = payload;

      const selectedCenterColor = state.centerColors.find((color) => color.key === key);

      if (selectedCenterColor) {
        state.selectedCenterColor = selectedCenterColor.value;
        // state.selectedThreadColor = selectedCenterColor.value;
        // state.threadColors = [selectedCenterColor];
      }
      // state.imgCover = selectedCenterColor.img;
    },

    // вставка
    setSelectedInsertColor: (state, { payload }) => {
      const { key } = payload;
      // state.keyInsertColor = key;

      const selectedInsertColor = state.insertColors.find((color) => color.key === key);

      if (selectedInsertColor) {
        state.selectedInsertColor = selectedInsertColor.value;
        // state.selectedThreadColor = selectedInsertColor.value;
        // state.threadColors = [selectedInsertColor];
      }
      // state.imgCover = selectedInsertColor.img;
    },

    // нитки
    setSelectedThreadColor: (state, { payload }) => {
      state.selectedThreadColor = payload;
    },

    cleanState: (state) => {
      state.imgAcc = '';
      state.title = '';
      state.typeM = '';
      state.price = 0;
    },
  },
});

export const {
  popelateCoverInitialState,
  setSelectedSideСolor,
  setSelectedCenterColor,
  setSelectedInsertColor,
  setSelectedThreadColor,
  cleanState,
} = coverSlice.actions;

export const coverReducer = coverSlice.reducer;
/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
